<template>
  <div :class="[$style.item, { [$style.border]: !!itemBorder }]">
    <div :class="[$style.product_box, { [$style.column]: !!gift }]">
      <div :class="$style.image_container">
        <AtomsConsumerLink
          :url="getSiteProductPageUrl(cartDetail.product)"
          :aria-label="cartDetail.product.name"
          :class="$style.img_wrapper"
        >
          <AtomsConsumerImage
            :class="$style.img"
            :src="cartDetail.sku.main_image"
            :alt="`${cartDetail.product.name} - ${cartDetail.sku.name}${
              cartDetail.sku.size ? `(${cartDetail.sku.size})` : null
            }の画像`"
            :width="72"
            :height="96"
          />
        </AtomsConsumerLink>
        <div v-if="gift?.sku_id" :class="$style.img_wrapper">
          <AtomsConsumerImage :src="gift?.img" :width="72" :height="96" />
        </div>
        <div v-if="gift?.message" :class="$style.img_wrapper">
          <AtomsConsumerImage :src="gift.message_img" :width="72" :height="96" />
        </div>
        <div v-if="gift?.card" :class="$style.img_wrapper">
          <AtomsConsumerImage :src="gift.card_img" :width="72" :height="96" />
        </div>
      </div>
      <div :class="$style.name_sku">
        <AtomsConsumerLink
          v-if="!cartDetail.product.is_gift"
          :url="getSiteProductPageUrl(cartDetail.product)"
          :class="[$style.name, $style.side_cart_link]"
        >
          {{ cartDetail.product.name }}
        </AtomsConsumerLink>
        <p v-else :class="$style.name">{{ cartDetail.product.name }}</p>
        <div :class="$style.sku">
          <p>{{ cartDetail.product.material }}</p>
          <p v-if="cartDetail.product.sex">{{ getSexLabel(cartDetail.product.sex) }}</p>
          <p v-if="!isEGift">
            <span v-if="cartDetail.sku.size"> サイズ: {{ cartDetail.sku.size }} </span>
            <span v-if="cartDetail.sku.color"> / 色: {{ cartDetail.sku.color }} </span>
          </p>
          <p v-else :class="$style.e_gift"> ※ サイズ・色はeギフトを受け取る方に選択してもらいます </p>
        </div>
      </div>
    </div>

    <div :class="$style.description_box">
      <div :class="$style.price_box">
        <div :class="$style.product_price">
          <p v-if="cartDetail.discounted_price === cartDetail.price" :class="$style.price">
            &yen; {{ priceWithComma(cartDetail.reference_unit_price * itemQuantity) }}
            <span>(参考税込)</span>
          </p>
          <template v-else>
            <p :class="$style.discount_announce">{{ isSubscription ? "定期購入割引" : "割引適用中" }}</p>
            <p :class="$style.strike">&yen; {{ priceWithComma(cartDetail.reference_unit_price * itemQuantity) }}</p>
            <p :class="[$style.price, $style.discounted]">
              &yen; {{ priceWithComma(cartDetail.reference_discounted_unit_price * itemQuantity) }}
              <span>(参考税込)</span>
            </p>
          </template>
        </div>
        <div v-if="gift?.sku_id" :class="$style.purchase_options_info">
          <!-- ラッピング -->
          <div :class="$style.purchase_options">
            <p :class="$style.name">{{ gift.product_name }}</p>
          </div>
          <p v-if="gift.price * gift.count !== 0" :class="$style.price">
            &yen; {{ priceWithComma(gift.price * gift.count) }}
            <span>(参考税込)</span>
          </p>
          <p v-else :class="$style.price">無料</p>
        </div>
        <div v-if="gift?.message" :class="$style.purchase_options_info">
          <div :class="$style.purchase_options">
            <p :class="$style.name">メッセージシール</p>
            <p :class="$style.detail">{{ gift.message }}</p>
          </div>
          <p v-if="gift.message_price * gift.count !== 0" :class="$style.price">
            &yen; {{ priceWithComma(gift.message_price * gift.count) }}
            <span>(参考税込)</span>
          </p>
          <p v-else :class="$style.price">無料</p>
        </div>
        <div v-if="gift?.card" :class="$style.purchase_options_info">
          <div :class="$style.purchase_options">
            <p :class="$style.name">メッセージカード</p>
            <p :class="$style.detail">{{ gift.card }}</p>
          </div>
          <p v-if="gift.card_price * gift.count !== 0" :class="$style.price">
            &yen; {{ priceWithComma(gift.card_price * gift.count) }}
            <span>(参考税込)</span>
          </p>
          <p v-else :class="$style.price">無料</p>
        </div>
        <!-- 刺繍 -->
        <div v-if="embroidery" :class="$style.purchase_options_info">
          <div :class="$style.purchase_options">
            <p :class="$style.name">刺繍内容</p>
            <p :class="$style.detail">刺繍テキスト：{{ embroidery.text }}</p>
            <p :class="$style.detail">刺繍位置：{{ embroidery.position }}</p>
            <p :class="$style.detail">カラー：{{ embroidery.color }}</p>
            <p :class="$style.detail">フォント：{{ embroidery.font }}</p>
          </div>
          <p v-if="embroidery.price > 0" :class="$style.price">
            &yen; {{ priceWithComma(embroidery.price * itemQuantity) }}<span>(参考税込)</span>
          </p>
          <p v-else>刺繍費用無料</p>
        </div>
        <div
          v-if="
            (cartDetail.product.order_quantity_limit_type && cartDetail.product.order_quantity_limit) ||
            cartDetail.product.is_not_individual_order ||
            (!embroidery && !cartDetail.product.is_gift && !canWrapping)
          "
          :class="$style.order_quantity_limit_description"
        >
          <p v-if="cartDetail.product.order_quantity_limit_type && cartDetail.product.order_quantity_limit">
            {{
              `※${cartDetail.product.order_quantity_limit_type === "order" ? "1回のご注文" : "通常商品1点"}ごとに${
                cartDetail.product.order_quantity_limit
              }個まで`
            }}
          </p>
          <p v-if="cartDetail.product.is_not_individual_order">
            {{ `※単体注文不可商品` }}
          </p>
          <p v-if="!embroidery && !cartDetail.product.is_gift && !canWrapping">
            {{ `※ギフトラッピング不可商品` }}
          </p>
        </div>
      </div>
      <div :class="$style.quantity_box">
        <div :class="$style.quantity_adjust">
          <div v-show="!isConfirm" :class="$style.reduce">
            <AtomsConsumerButton :class="$style.button" class="reduce_btn" @click="reduceCartItem" />
          </div>
          <div :class="$style.quantity">
            <p>{{ (isConfirm ? "数量: " : "") + itemQuantity }}</p>
          </div>
          <div v-show="!isConfirm" :class="$style.increase">
            <AtomsConsumerButton
              v-show="addBtnDisplay"
              class="increase_btn"
              :class="$style.button"
              :disabled="isExchangeCoupon"
              @click="addCartItem"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!embroidery && !cartDetail.product.is_gift"
        v-show="!isConfirm && canWrapping"
        :class="$style.wrapping_button"
      >
        <AtomsConsumerCheckbox
          v-model="isWrapping"
          name="is-wrapping"
          :class="$style.checkbox"
          @click="toggleGiftWrapping($event)"
        >
          {{ !hasSetWrapping ? "ギフトラッピングを設定する" : "ギフトラッピング設定済み" }}
        </AtomsConsumerCheckbox>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductAndSku, SkuWithGift } from "@tential/ec-gql-schema/types/cart"
import { CartEmbroidery } from "@tential/ec-gql-schema/models/order"
import { useStore } from "~/stores"
import { getSiteProductPageUrl } from "~/utils/functions/product"
import { priceWithComma } from "~/utils/functions/price"
import { ElementEvent, EventHandler } from "~/types/events"
import { SEX_ITEMS } from "~/const/product"

type Props = {
  /** カートデータ */
  cartDetail: ProductAndSku
  /** 刺繍データ */
  embroidery?: CartEmbroidery & {
    embroidered_sku_id?: string // 刺繍設定したSKUのID
    count?: number // 該当する刺繍に紐づくskuの合計
  }
  /** ギフトデータ */
  gift?: SkuWithGift
  /** サブスク */
  isSubscription?: boolean
  /** 確認画面かどうか */
  isConfirm: boolean
  /** 交換クーポンかどうか */
  isExchangeCoupon?: boolean
  /** 数量加算ボタンを表示するか */
  addBtnDisplay?: boolean
  /** ギフトラッピングが設定可能か */
  canWrapping?: boolean
  /** ギフトラッピングが設定されているか */
  hasSetWrapping?: boolean
  /** 境界線を表示するか */
  itemBorder: boolean
}

const props = withDefaults(defineProps<Props>(), {
  embroidery: undefined,
  gift: undefined,
  isSubscription: false,
  isExchangeCoupon: false,
  addBtnDisplay: true,
  canWrapping: true,
  hasSetWrapping: false,
})
const emit = defineEmits<{
  (e: "onReduceCartItem"): void
  (e: "onAddCartItem"): void
  (e: "onOpenSelectGiftModal"): void
  (e: "onInitSelectGift"): void
}>()

const store = useStore()
const isWrapping = ref<boolean>(props.hasSetWrapping)
const isEGift = computed<boolean>(() => store.eGift.is_select)
const itemQuantity = computed<number>(() => {
  if (props.embroidery?.count) return props.embroidery.count
  if (props.gift) return props.gift.count
  return props.cartDetail.count
})

const getSexLabel = (sexValue: string): string => {
  for (const item of SEX_ITEMS) {
    if (item.value === sexValue) return item.key
  }
  return ""
}

const toggleGiftWrapping: EventHandler = (e: ElementEvent<HTMLInputElement>): void => {
  e.preventDefault()
  if (props.hasSetWrapping) {
    emit("onInitSelectGift")
    return
  }
  emit("onOpenSelectGiftModal")
}
const reduceCartItem = (): void => {
  emit("onReduceCartItem")
}
const addCartItem = (): void => {
  emit("onAddCartItem")
}
</script>

<style scoped module lang="scss">
.item {
  background-color: $white;
  border-radius: 0.25rem;
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  &.border {
    border: 1px solid $secondary-20;
  }
  .product_box {
    display: flex;
    gap: 1.5rem;
    &.column {
      flex-direction: column;
    }
    .image_container {
      display: flex;
      gap: 0.5rem;
      .img_wrapper {
        position: relative;
        &:not(:last-child)::after {
          position: absolute;
          top: 50%;
          right: -20px;
          font-size: $font-size-16;
          content: "+";
          transform: translateY(-50%);
        }
        &:not(:first-child) {
          margin-left: 20px;
        }
        > img {
          border-radius: 6px;
        }
      }
    }
    .name_sku {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      .name {
        @include main-font;
        font-size: $font-size-14;
        font-weight: bold;
        width: fit-content;
      }
      .sku {
        font-size: $font-size-12;
        line-height: 1.5;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.25rem;
        @include md {
          line-height: inherit;
        }
        .e_gift {
          line-height: 1rem;
          font-size: $font-size-10;
          @include md {
            line-height: inherit;
          }
        }
      }
    }
    .side_cart_link {
      text-decoration: underline;
    }
  }
  .description_box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    gap: 1.25rem;
    .price_box {
      width: 100%;
      text-align: right;
      font-size: $font-size-14;
      line-height: 18px;
      display: flex;
      flex-direction: column;
      gap: 1.75rem;
      .product_price {
        .price {
          @include main-font;
          font-size: $font-size-20;
          span {
            font-size: $font-size-10;
            @include md {
              font-size: $font-size-12;
            }
          }
        }
        .strike {
          font-size: $font-size-10;
          margin-bottom: 0.75rem;
          text-decoration: line-through;
          @include md {
            font-size: $font-size-12;
          }
        }
        .discounted,
        .discount_announce {
          color: $red;
        }
        .discount_announce {
          font-size: $font-size-12;
          font-weight: normal;
          @include md {
            font-size: $font-size-14;
          }
        }
      }
      .purchase_options_info {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .purchase_options {
          text-align: left;
          .name {
            @include main-font;
            font-size: $font-size-12;
            font-weight: bold;
          }
          .detail {
            font-size: $font-size-12;
          }
        }
        .price {
          @include main-font;
          font-size: $font-size-20;
          span {
            font-size: $font-size-10;
            @include md {
              font-size: $font-size-12;
            }
          }
        }
      }
      .order_quantity_limit_description {
        font-weight: normal;
        font-size: $font-size-10;
      }
    }
    .quantity_box {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem 0.75rem;
      flex-wrap: wrap;
      flex-direction: column;
      .quantity_adjust {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.75rem;
        .reduce {
          position: relative;
          width: 30px;
          height: 30px;
          color: $primary;
          border-radius: 50%;
          border: 1px solid $secondary-40;
          background-color: $white;
          .button {
            &::before {
              width: 10px;
              height: 1px;
            }
          }
        }
        .quantity p {
          @include main-font;
          color: $primary;
        }
        .increase {
          position: relative;
          width: 30px;
          height: 30px;
          color: $primary;
          border-radius: 50%;
          border: 1px solid $secondary-40;
          background-color: $white;
          .button {
            &::before {
              width: 10px;
              height: 1px;
            }

            &::after {
              width: 1px;
              height: 10px;
            }
          }
        }
        button {
          width: 100%;
          height: 100%;
          padding: 0;
          cursor: pointer;
          &::before,
          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            content: "";
            background-color: $primary;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .wrapping_button {
      border-top: 1px solid $secondary-20;
      width: 100%;
      justify-items: flex-start;
      padding: 1.5rem 0.5rem 0.25rem;
      .checkbox {
        color: $primary-80;
        text-decoration: underline;
        width: fit-content;
        line-height: 1.2rem;
        align-items: flex-start;
      }
    }
  }
}
</style>
